import { customElement } from "solid-element"
import { setTranslations, translations } from "../../services/context"
import { getTranslations } from "../../services/translation"
import { For, onMount, Show, createSignal, onCleanup } from "solid-js"
import { sidebar, welcomeToolkit, breadcrumb, filesNav, filesFilters, previewSVGs, filesActions, checkbox, uploadPopup, anotherSVGs, alerts } from './assets/assets'
import { sendRequest } from "../../services/office-api"
import { sendGFRequest } from "../../services/global-file-api"

const [step, setStep] = createSignal('home')
const [loading, setLoading] = createSignal(false)
const [uploadLoading, setUploadLoading] = createSignal(false)
const [myFolders, setMyFolders] = createSignal(null)
const [stockFolders, setStockFolders] = createSignal(null)
const [myFiles, setMyFiles] = createSignal(null)
const [stockFiles, setStockFiles] = createSignal(null)
const [myDeletedFiles, setMyDeletedFiles] = createSignal(null)
const [pagination, setPagination] = createSignal(null)
const [searchData, setSearchData] = createSignal(null)
const [newFolderName, setNewFolderName] = createSignal(null)
const [newFileName, setNewFileName] = createSignal(null)
const [searchTimer, setSearchTimer] = createSignal(null)
const [selectedFolderToUploadFile, setSelectedFolderToUploadFile] = createSignal(null)
const [selectedFolderToMoveFile, setSelectedFolderToMoveFile] = createSignal(null)
const [currentFolder, setCurrentFolder] = createSignal(null)
const [newUploadFile, setNewUploadFile] = createSignal([])
const [selectAllFiles, setSelectAllFiles] = createSignal(false)
const [showUploadFilePopup, setShowUploadFilePopup] = createSignal(false)
const [showDeleteFilePopup, setShowDeleteFilePopup] = createSignal(false)
const [showDeleteFolderPopup, setShowDeleteFolderPopup] = createSignal(false)
const [showNewFolderPopup, setShowNewFolderPopup] = createSignal(false)
const [showRenameFilePopup, setShowRenameFilePopup] = createSignal(false)
const [showMoveFilePopup, setShowMoveFilePopup] = createSignal(false)
const [showDeletedFolder, setShowDeletedFolder] = createSignal(false)
const [selectedFiles, setSelectedFiles] = createSignal([])
const [typeFileSelected, setTypeFileSelected] = createSignal('all')
const [filterAscDesc, setFilterAscDesc] = createSignal('desc')
const [filterGridList, setFilterGridList] = createSignal('grid')
const [percentCompleted, setPercentCompleted] = createSignal(0)
const [quotas, setQuotas] = createSignal({
  in_use: 0,
  released: 15
})
const [showToast, setShowToast] = createSignal(false)
const [toastData, setToastData] = createSignal({
  type: 'ba-success',
  message: 'Successfully uploaded'
})

const fileTypes = [
  { slug: 'all' },
  { slug: 'image' },
  { slug: 'audio' },
  { slug: 'video' },
  { slug: 'gif' },
  { slug: 'other' }
]

const routes = [
  { slug: 'home',   title: 'Home' },
  { slug: 'files',  title: 'My Files' },
  { slug: 'stock',  title: 'Stock Elements' },
  { slug: 'brands', title: 'My Brands' }
]

// Language
if (!translations['file-exceed-limit']) {
  let userData = JSON.parse(localStorage.getItem("builderall-appshell--resources"))
  var userLang = !userData || !userData.user.lang ? 'us' : !userData.user.lang.split("_")[0] ? 'us' : userData.user.lang.split("_")[0]
  userLang = userLang.toLowerCase()
  getTranslations(userLang).then((r => setTranslations(r)))
}

customElement("builderall-digital-file-repository", { tool: "", token: "", show: false, step: null, inputid: null, accept: null, showeditoption: false }, props => {

  onMount(async () => {
    setLoading(true)
    var [quotas] = await Promise.all([
      getQuotas()
    ])

    setQuotas(quotas.storage)
    setLoading(false)
  })

  // Methods
  const changeFilterAscDesc = (data) => {
    setFilterAscDesc(data)
    getFiles()
  }

  const changeCurrentFolder = (data) => {
    setShowDeletedFolder(false)
    setCurrentFolder(data)
    getFiles()
  }

  const changeTypeFile = (data) => {
    setTypeFileSelected(!data || !data.slug ? 'all' : data.slug)
    getFiles()
  }

  const changeRoute = async (slug: string) => {
    setStep(slug)

    if (slug == 'files' && isEmpty(myFolders())) {
      getFiles()
      setLoading(true)

      var [userFolders] = await Promise.all([
        getMyFolders()
      ])

      setLoading(false)
      setMyFolders(userFolders)
    } else if (slug == 'stock' && isEmpty(myFolders())) {
      getStockFiles()
      setLoading(true)

      var [stockFolders] = await Promise.all([
        getStockFolders()
      ])

      setLoading(false)
      defineStockFolders(stockFolders)
    }

    // Reset
    changeCurrentFolder(null)
    changeTypeFile(null)
    setFilterAscDesc('desc')
    setFilterGridList('grid')
    setSearchData(null)
  }

  const defineStockFolders = (data) => {
    var newStockFoldersStructure = []
    data.forEach((x,i) => {
      if(typeof x == 'string') {
        let newData = {
          title: x, 
          folders: data[i+1],
          show_secondary: false
        }
        newStockFoldersStructure.push(newData)
      }
    })
    setStockFolders(newStockFoldersStructure)
  }

  const changeUploadFile = (event) => {
    const file = event.target.files[0]
    if (file) {
      const fileSizeMB = Math.ceil(file.size / (1024 * 1024))

      if (fileSizeMB > 50) {
        alertMsg('ba-alert', translations['file-exceed-limit'])

        if (fileSizeMB >= (quotas().released - quotas().in_use)) {
          alertMsg('ba-error', translations['file-exceed-limit-to-upload'])
        }

        event.target.value = ''
      } else {
        setNewUploadFile([file])
      }
    }
  }

  const getExtensionType = () => {
    const files = newUploadFile()

    if (!files || files.length === 0 || files.length > 1) return "OTHER"

    const extension = files[0].name.split('.').pop()?.toLowerCase()
    const types = [
      { ext: ['gif'], svg: 'GIF' },
      { ext: ['mp4', 'mkv', 'mov', 'avi', 'wmv', 'flv', 'webm'], svg: 'VIDEO' },
      { ext: ['png', 'jpg', 'jpeg', 'bmp', 'tiff', 'webp', 'svg'], svg: 'IMAGE' },
      { ext: ['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a'], svg: 'AUDIO' }
    ]
    const type = types.find(t => t.ext.includes(extension))

    return type ? type.svg : 'OTHER'
  }

  const sendMoveFile = async () => {
    setLoading(true)
    let hasError = false

    const promises = selectedFiles().map(async (file) => {
      try {
        const body = {
          to: selectedFolderToMoveFile(),
          hash: file
        }
        const { success } = await sendGFRequest(props.token, `files/move`, 'PUT', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', 'Error move one or more files')
    } else {
      alertMsg('ba-success', 'File(s) moved successfully')
    }

    setCurrentFolder(selectedFolderToMoveFile())
    organizeMyFoldersScroll()
    hideMoveFilePopup()
    getFiles()
  }

  const sendUploadedFile = async () => {
    setShowUploadFilePopup(false)

    const uploadPromises = newUploadFile().map(file => {
      return new Promise((resolve, reject) => {
        setUploadLoading(true)
        var formData = new FormData()
        formData.append('folder', selectedFolderToUploadFile() ?? translations['General'])
        formData.append('file', file)

        var xhr = new XMLHttpRequest()
        xhr.open('POST', `${process.env.GLOBAL_FILE_API}/v1/files/upload`, true)
        xhr.setRequestHeader('Authorization', `Bearer ${props.token}`)

        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            var percentCompleted = Math.round((event.loaded * 100) / event.total)
            setPercentCompleted(percentCompleted)
          }
        }

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(null)
          } else {
            console.error('Erro ao enviar o arquivo. Status:', xhr.status)
            alertMsg('ba-error', 'Error on Upload file')
            setUploadLoading(false)
            reject()
          }
        }

        xhr.onerror = function () {
          console.error('Erro na requisição de upload')
          alertMsg('ba-error', 'Error on Upload file')
          setUploadLoading(false)
          reject()
        }

        xhr.send(formData)
      })
    })

    try {
      await Promise.all(uploadPromises)

      setUploadLoading(false)
      alertMsg('ba-success', 'Successfully uploaded')
      setTimeout(() => {
        setTypeFileSelected('all')
        getFiles()
        setPercentCompleted(0)
        setNewUploadFile([])
        setCurrentFolder(selectedFolderToUploadFile())
        organizeMyFoldersScroll()
        setSelectedFolderToUploadFile(null)
      }, 500)

    } catch (error) {
      console.error('Erro durante o upload de um ou mais arquivos:', error)
    }
  }

  const openUploadPopup = () => {
    setShowUploadFilePopup(true)
    setSelectedFolderToUploadFile(currentFolder())

    // Organize scroll position
    const webComponent = document.querySelector("builderall-digital-file-repository")
    const shadowRoot = webComponent?.shadowRoot
    if (shadowRoot) {
      const container = shadowRoot.getElementById('folders-to-make-upload')
      const activeElement = container.querySelector(".active")

      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }

  const changeSelectedFile = (data, replace = false) => {
    if (replace) {
      setSelectedFiles([data.hash])
    } else {
      setSelectedFiles((prev) => {
        if (prev.includes(data.hash)) {
          return prev.filter((hash) => hash !== data.hash)
        } else {
          return [...prev, data.hash]
        }
      })
    }

    if (!isEmpty(selectedFiles()) && !isEmpty(myFiles()) && selectedFiles().length == myFiles().length) {
      setSelectAllFiles(true)
    } else {
      setSelectAllFiles(false)
    }
  }

  const changeSelectAllFiles = () => {
    setSelectAllFiles(!selectAllFiles())
    if (selectAllFiles()) {
      setSelectedFiles(myFiles().map(x => x.hash))
    } else {
      setSelectedFiles([])
    }
  }

  const hideUploadFilePopup = () => {
    setShowUploadFilePopup(false)
    setSelectedFolderToUploadFile(null)
    setPercentCompleted(0)
    setNewUploadFile([])
  }

  const hideNewFolderPopup = () => {
    setShowNewFolderPopup(false)
    setNewFolderName(null)
  }

  const hideDeleteFilePopup = () => {
    setShowDeleteFilePopup(false)
  }

  const hideMoveFilePopup = () => {
    setShowMoveFilePopup(false)
    setSelectedFolderToMoveFile(null)
  }

  const sendNewFolder = async () => {
    setLoading(true)

    if (isEmpty(newFolderName())) {
      alertMsg('ba-error', 'Digite um nome para a sua nova pasta')
      setLoading(false)
      return
    } else if (folderAlreadyCreated()) {
      alertMsg('ba-error', 'Já existe uma pasta com esse nome. Tente outro')
      setLoading(false)
      return
    } else if (newFolderName().length > 50) {
      alertMsg('ba-error', 'O nome da pasta não pode ter mais que 50 caracteres')
      setLoading(false)
      return
    }

    var body = {
      folder: newFolderName()
    }

    var { data, success, message } = await sendGFRequest(props.token, `folders/create`, 'POST', body)

    if (success) {
      var [userFolders] = await Promise.all([
        getMyFolders()
      ])

      setMyFolders(userFolders)
      setCurrentFolder(newFolderName())
      organizeMyFoldersScroll()
      getFiles()
      hideNewFolderPopup()
    }

    setLoading(false)
    var type = success ? 'ba-success' : 'ba-error'
    alertMsg(type, message)
  }

  const folderAlreadyCreated = () => {
    if (!isEmpty(myFolders())) {
      const folderExists = myFolders().some(f => f.toString().toLowerCase() === newFolderName().toString().toLowerCase())
      return folderExists
    }
    return false
  }

  const sendDeleteFile = async () => {
    setLoading(true)

    const files = selectedFiles()
    const deletePromises = files.map((hash) =>
      sendGFRequestWithData(`files/delete`, 'DELETE', { hash })
    )

    await Promise.all(deletePromises)

    setLoading(false)
    hideDeleteFilePopup()
    getFiles()
  }

  const hideDeleteFolderPopup = () => {
    setShowDeleteFolderPopup(false)
  }

  const hideRenameFilePopup = () => {
    setShowRenameFilePopup(false)
    setNewFileName(null)
  }

  const sendDeleteFolder = async () => {
    setLoading(true)
    var { data, success, message } = await sendGFRequest(props.token, `folders/delete?folder=${encodeURIComponent(currentFolder())}`, 'DELETE', null)
    setLoading(false)

    if (success) {
      hideDeleteFolderPopup()
      setCurrentFolder(null)
      var [userFolders] = await Promise.all([
        getMyFolders()
      ])
      setMyFolders(userFolders)
      getFiles()
      organizeMyFoldersScroll()
    }

    var type = success ? 'ba-success' : 'ba-error'
    alertMsg(type, message)
  }

  const sendRenameFile = async () => {
    setLoading(true)

    var body = {
      hash: selectedFiles()[0],
      new_filename: newFileName()
    }
    var { data, success, message } = await sendGFRequest(props.token, `files/update/filename`, 'PUT', body)
    setLoading(false)

    if(success) {
      hideRenameFilePopup()
      getFiles()
    }

    var type = success ? 'ba-success' : 'ba-error'
    alertMsg(type, message)
  }

  const defineSearchData = (e) => {
    setSearchData(e.target.value)

    if (searchTimer()) {
      clearInterval(searchTimer())
    }

    let timerToSearch = setTimeout(() => {
      getFiles()
    }, 1500)

    setSearchTimer(timerToSearch)
  }

  const continueFile = async () => {
    const file = myFiles().find(x => x.hash === selectedFiles()[0])
    const validFile = await validateFile(file)
    if (!validFile) {
      return false
    }

    const fileInput = document.getElementById(props.inputid) as HTMLInputElement
    if (fileInput) {
      fileInput.value = file.url
      const event = new Event('input', { bubbles: true })
      fileInput.dispatchEvent(event)
      closeModal()
    } else {
      console.error('Input to receive data not found.')
    }
  }

  function validateFile(file) {
    const extensions = props.accept.split(',')
    if (file?.hash && !validateFileExtension(file.hash, extensions)) {
      return false
    }
    return true
  }

  function validateFileExtension(fileName: string, allowedExtensions: string[]): boolean {
    const extension = fileName.split('.').pop()?.toLowerCase()
    return allowedExtensions.includes(`.${extension}`)
  }

  const alertMsg = (type, message) => {
    setToastData({
      type: type,
      message: message
    })

    setShowToast(true)

    const timer = setTimeout(() => {
      setShowToast(false)
    }, 5000)

    onCleanup(() => clearTimeout(timer))
  }

  const isEmpty = (val) => {
    return val === 0 || val === undefined || val == null || val.length <= 0 || (typeof val === 'object' && Object.keys(val).length === 0) ? true : false
  }

  const getQuotas = async () => {
    var data = await sendRequest(props.token, `profile/storage`)

    if (!isEmpty(data)) {
      return data
    }
  }

  const organizeMyFoldersScroll = () => {
    const webComponent = document.querySelector("builderall-digital-file-repository")
    const shadowRoot = webComponent?.shadowRoot
    if (shadowRoot) {
      const container = shadowRoot.getElementById('my-folders-list')
      const activeElement = container.querySelector(".active")

      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }

  const getMyFolders = async () => {
    var { data, success, message } = await sendGFRequest(props.token, 'folders/list', 'GET', null)

    if (success) {
      return data
    }
  }

  const getStockFolders = async () => {
    var { data, success, message } = await sendGFRequest(props.token, 'builderall-files/folders', 'GET', null)

    if (success) {
      return data
    }
  }

  const getFiles = async (page: number = 1) => {
    setLoading(true)

    const typeFile = typeFileSelected() == 'all' ? '' : typeFileSelected()
    const folderName = encodeURIComponent(isEmpty(currentFolder()) ? '' : currentFolder())
    const search = isEmpty(searchData()) ? '' : searchData()

    var { data, success, message, pagination } = await sendGFRequest(props.token, `files/paginated?folder=${folderName}&search=${search}&type=${typeFile}&order_direction=${filterAscDesc()}&page=${page}`, 'GET', null)
    setLoading(false)

    if (success) {
      setMyFiles(data)
      setPagination(pagination)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const getStockFiles = async (page: number = 1) => {
    setLoading(true)

    const typeFile = typeFileSelected() == 'all' ? '' : typeFileSelected()
    const folderName = encodeURIComponent(isEmpty(currentFolder()) ? '' : currentFolder())
    const search = isEmpty(searchData()) ? '' : searchData()

    var { data, success, message, pagination } = await sendGFRequest(props.token, `builderall-files/paginated??folder=${folderName}&search=${search}&type=${typeFile}&order_direction=${filterAscDesc()}&page=${page}`, 'GET', null)
    setLoading(false)

    if (success) {
      setStockFiles(data)
      setPagination(pagination)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const restoreFiles = async () => {
    setLoading(true)
    let hasError = false

    const promises = selectedFiles().map(async (file) => {
      try {
        const body = { hash: file }
        const { success } = await sendGFRequest(props.token, 'trash/restore', 'POST', body)
        if (!success) hasError = true
      } catch (error) {
        hasError = true
      }
    })

    await Promise.all(promises)
    setLoading(false)

    if (hasError) {
      alertMsg('ba-error', 'Error restoring one or more files')
    } else {
      alertMsg('ba-success', 'File(s) restored successfully')
    }

    getDeletedFiles()
  }

  const getDeletedFiles = async () => {
    setLoading(true)

    var { data, success, message } = await sendGFRequest(props.token, `trash/list?search=${searchData()}`, 'GET', null)

    setLoading(false)

    if (success) {
      setMyDeletedFiles(data)
      setSelectAllFiles(false)
      setSelectedFiles([])
    }
  }

  const changeShowDeletedFolder = () => {
    setShowDeletedFolder(true)
    getDeletedFiles()

    // Reset
    setTypeFileSelected('all')
    setCurrentFolder(null)
    setSelectAllFiles(false)
    setSelectedFiles([])
    setFilterAscDesc('desc')
    setFilterGridList('grid')
    setSearchData(null)
    setMyFiles(null)
  }

  const changeSecondaryMenuStatus = (data) => {
    setStockFolders((prev) => 
      prev.map((folder) => 
        folder.title === data 
          ? { ...folder, show_secondary: !folder.show_secondary } 
          : folder
      )
    )
  }  

  const closeModal = () => {
    const customEvent = new CustomEvent("handle-close-global-file", {
      composed: true,
      detail: props
    })
    document.dispatchEvent(customEvent)
    props.show = false
    changeRoute('home')
  }

  const sendGFRequestWithData = async (path, method, body) => {
    setLoading(true)

    var { data, success, message } = await sendGFRequest(props.token, path, method, body)

    if (success) {
      setLoading(false)
    }

    var type = success ? 'ba-success' : 'ba-error'
    alertMsg(type, message)

    return success
  }

  // Components
  function Breadcrumb() {
    return (
      <div class="breadcrumb">
        <div innerHTML={breadcrumb.default}></div>
        <div innerHTML={breadcrumb.arrow}></div>
        <span>{routes.find(x => x.slug == step()).title}</span>
        <div innerHTML={breadcrumb.arrow}></div>
        <span>{showDeletedFolder() ? 'Deleted' : isEmpty(currentFolder()) ? 'All' : currentFolder()}</span>
      </div>
    )
  }

  function RenameFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideRenameFilePopup()}
      >
        <div
          class="popup rename-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">Rename File</h3>
          </div>
          <div class="popup-main-input">
            <label for="new-folder">Name</label>
            <input
              type="text"
              id="new-folder"
              value={newFileName()}
              onInput={(e) => setNewFileName(e.target.value)}
            />
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideRenameFilePopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendRenameFile()}
              disabled={isEmpty(newFileName())}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  function NewFolderPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideNewFolderPopup()}
      >
        <div
          class="popup new-folder"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">New Folder</h3>
          </div>
          <div class="popup-main-input">
            <label for="new-folder">Name</label>
            <input
              type="text"
              id="new-folder"
              value={newFolderName()}
              onInput={(e) => setNewFolderName(e.target.value)}
            />
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideNewFolderPopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendNewFolder()}
              disabled={isEmpty(newFolderName())}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  function DeleteFolderPopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideDeleteFolderPopup()}
      >
        <div
          class="popup delete-folder"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">Delete Folder</h3>
            <p class="popup-description">Are you sure you want to delete the folder [{currentFolder()}] ?</p>
          </div>
          <div class="popup-main">
            <span>When deleting the folder, all its contents will be deleted as well.</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideDeleteFolderPopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendDeleteFolder()}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    )
  }

  function DeleteFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideDeleteFilePopup()}
      >
        <div
          class="popup delete-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">Delete File(s)</h3>
            <p class="popup-description">Are you sure you want to delete the File(s)?</p>
          </div>
          <div class="popup-main">
            <span>Você irá deletar {selectedFiles().length} arquivos. Ao deletar você ainda poderá restaurar os arquivos na pasta "Deleted".</span>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideDeleteFilePopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-red"
              onClick={() => sendDeleteFile()}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    )
  }

  function UploadFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideUploadFilePopup()}
      >
        <div
          class="popup upload-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <h3 class="popup-title">Upload File</h3>
            <p class="popup-description">Select the folder where you want to save the file.</p>
          </div>
          <div class="popup-main">
            <label class="upload-element">
              <div innerHTML={uploadPopup.upload}></div>
              <input type="file" accept={props.accept} onChange={(e) => changeUploadFile(e)} />
              <span>{isEmpty(newUploadFile()) ? 'Upload your file Max 50mb' : newUploadFile()[0].name}</span>
            </label>
            <div class="folders-box">
              <div class="current-folder">
                <div class="main-icon" innerHTML={sidebar.myFiles} />
                <div innerHTML={breadcrumb.arrow}></div>
                <span>{isEmpty(selectedFolderToUploadFile()) ? 'All' : selectedFolderToUploadFile()}</span>
              </div>
              <div class="folders-list" id="folders-to-make-upload">
                <button
                  classList={{ 'active': isEmpty(selectedFolderToUploadFile()) }}
                  onClick={() => setSelectedFolderToUploadFile(null)}
                >
                  <div innerHTML={sidebar.myFiles}></div>
                  All
                </button>
                <For each={myFolders()}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedFolderToUploadFile() == item }}
                        onClick={() => setSelectedFolderToUploadFile(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideUploadFilePopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendUploadedFile()}
              disabled={isEmpty(newUploadFile())}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  function MoveFilePopup() {
    return (
      <div
        class="popup-backdrop"
        onClick={() => hideMoveFilePopup()}
      >
        <div
          class="popup move-file"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="popup-header">
            <div class="two-elements">
              <h3 class="popup-title">Move File ({selectedFiles().length})</h3>
              <button 
                class="ba-btn ba-blue"
                onClick={() => { hideMoveFilePopup(), setShowNewFolderPopup(true) }}
              >
                New Folder
              </button>
            </div>
            <Show when={!isEmpty(selectedFolderToMoveFile())}>
              <div class="current-folder">
                <span>{isEmpty(currentFolder()) ? 'All' : currentFolder()}</span>
                  <div innerHTML={breadcrumb.arrow}></div>
                  <span>{selectedFolderToMoveFile()}</span>
              </div>
            </Show>
          </div>
          <div class="popup-main">
            <div class="current-folder">
              <div class="main-icon" innerHTML={sidebar.myFiles}></div>
              <span>{isEmpty(currentFolder()) ? 'All' : currentFolder()}</span>
            </div>
            <hr />
            <div class="folders-box">
              <div class="folders-list" id="folders-to-make-upload">
                <For each={myFolders().filter(x => x != currentFolder())}>
                  {(item, index) => (
                    <>
                      <button
                        classList={{ 'active': selectedFolderToMoveFile() == item }}
                        onClick={() => setSelectedFolderToMoveFile(item)}
                      >
                        <div innerHTML={sidebar.myFiles}></div>
                        {item}
                      </button>
                    </>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button
              class="ba-btn ba-white"
              onClick={() => hideMoveFilePopup()}
            >
              Cancel
            </button>
            <button
              class="ba-btn ba-blue"
              onClick={() => sendMoveFile()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  function Pagination() {
    return (
      <nav class='ba-pagination ba-md'>
        <ul class='ba-list'>
          <Show when={pagination().current_page > 1}>
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(pagination().current_page - 1)}
                class='ba-back'
              >
                <span class='ba-icon'>
                  <builderall-icon code='arrow-left' />
                </span>
              </a>
            </li>
          </Show>
          <Show when={pagination().last_page > 6} fallback={
            <For each={[...Array(pagination().last_page).keys()].map(i => i + 1)}>
              {(page, index) => (
                <li class='ba-nav-item'>
                  <a
                    onClick={() => getFiles(page)}
                    classList={{ 'ba-link': true, 'ba-is-active': pagination().current_page == page }}
                  >
                    {page}
                  </a>
                </li>
              )}
            </For>
          }>
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(1)}
                class='ba-link ba-is-active'
              >1</a>
            </li>
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(2)}
                class='ba-link'
              >2</a>
            </li>
            ...
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(pagination().last_page - 1)}
                class='ba-link'
              >
                {pagination().last_page - 1}
              </a>
            </li>
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(pagination().last_page)}
                class='ba-link'
              >
                {pagination().last_page}
              </a>
            </li>
          </Show>
          <Show when={pagination().current_page != pagination().last_page}>
            <li class='ba-nav-item'>
              <a
                onClick={() => getFiles(pagination().current_page + 1)}
                class='ba-next'
              >
                <span class='ba-icon'>
                  <builderall-icon code='arrow-right' />
                </span>
              </a>
            </li>
          </Show>
        </ul>
      </nav>
    )
  }

  function FilesNav() {
    return (
      <div class="files-nav">
        <For each={fileTypes}>
          {(item, index) => (
            <>
              <button
                classList={{ 'active': typeFileSelected() == item.slug }}
                innerHTML={filesNav[item.slug]}
                onClick={() => changeTypeFile(item)}
              ></button>
            </>
          )}
        </For>
      </div>
    )
  }

  function StockFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="left-filters">
            <label>
              <div
                class="checkbox"
                innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
              ></div>
              <input type="checkbox" onInput={() => changeSelectAllFiles()} />
              Select All
            </label>
          </div>
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterAscDesc()]}></div>
                {filterAscDesc() == 'asc' ? 'Ascending' : 'Descending'}
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                <div innerHTML={filesFilters.asc}></div>
                Ascending
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                <div innerHTML={filesFilters.desc}></div>
                Descending
              </button>
            </div>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'grid' }}
              innerHTML={filesFilters.grid}
              onClick={() => setFilterGridList('grid')}
            ></button>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'list' }}
              innerHTML={filesFilters.list}
              onClick={() => setFilterGridList('list')}
            ></button>
          </div>
        </div>
      </Show>
    )
  }

  function FilesFilter() {
    return (
      <Show when={!loading()}>
        <div class="files-filters">
          <div class="left-filters">
            <label>
              <div
                class="checkbox"
                innerHTML={selectAllFiles() ? checkbox.checked : checkbox.checkbox}
              ></div>
              <input type="checkbox" onInput={() => changeSelectAllFiles()} />
              Select All
            </label>
            <Show when={!isEmpty(selectedFiles()) && selectedFiles().length > 0}>
              <button 
                innerHTML={filesFilters.move}
                onClick={() => setShowMoveFilePopup(true)}
              ></button>
              <button 
                innerHTML={filesActions.delete} 
                onClick={() => setShowDeleteFilePopup(true)}
              ></button>
            </Show>
          </div>
          <div class="right-filters">
            <button class="btn-open-select">
              <div>
                <div class="current-icon" innerHTML={filesFilters[filterAscDesc()]}></div>
                {filterAscDesc() == 'asc' ? 'Ascending' : 'Descending'}
              </div>
              <div class="arrow-icon" innerHTML={filesFilters.arrow}></div>
            </button>
            <div class="select-box">
              <button onClick={() => changeFilterAscDesc('asc')}>
                <div innerHTML={filesFilters.asc}></div>
                Ascending
              </button>
              <button onClick={() => changeFilterAscDesc('desc')}>
                <div innerHTML={filesFilters.desc}></div>
                Descending
              </button>
            </div>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'grid' }}
              innerHTML={filesFilters.grid}
              onClick={() => setFilterGridList('grid')}
            ></button>
            <button
              classList={{ 'btn-grid-list': true, 'active': filterGridList() == 'list' }}
              innerHTML={filesFilters.list}
              onClick={() => setFilterGridList('list')}
            ></button>
          </div>
        </div>
      </Show>
    )
  }

  function FilesList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box">
          <Show when={filterGridList() == 'grid'} fallback={
            <div class="files-list">
              <Show when={!isEmpty(myFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>No files yet. Click to add content.</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    New Upload
                  </button>
                </div>
              }>
                <For each={myFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div class="file-el-left">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={isEmpty(item.preview) ? item.url : item.preview}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                        </div>
                        <div class="file-el-right">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item, true), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button innerHTML={filesActions.edit}></button>
                          </Show>
                          <a
                            href={isEmpty(item.preview) ? item.url : item.preview}
                            target="_blank"
                            innerHTML={filesActions.preview}
                          ></a>
                          <button
                            class="btn-delete"
                            innerHTML={filesActions.delete}
                            onClick={() => { changeSelectedFile(item, true), setShowDeleteFilePopup(true) }}
                          ></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
            <div class="files-grid">
              <Show when={!isEmpty(myFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>No files yet. Click to add content.</span>
                  <button
                    class="ba-btn ba-blue"
                    onClick={() => openUploadPopup()}
                  >
                    <builderall-icon code='2780'></builderall-icon>
                    New Upload
                  </button>
                </div>
              }>
                <For each={myFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div
                          class="checkbox"
                          innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                        ></div>
                        <input
                          type="checkbox"
                          onInput={() => changeSelectedFile(item)}
                          checked={selectedFiles().includes(item.hash)}
                        />
                        {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                          <img
                            class="file-preview"
                            src={isEmpty(item.preview) ? item.url : item.preview}
                            onError={(e) => {
                              e.target.style.display = 'none'
                              e.target.nextSibling.style.display = 'block'
                            }}
                          />
                        ) : (
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                          />
                        )}
                        <div
                          class="file-icon"
                          innerHTML={previewSVGs[item.type]}
                          style={{ display: 'none' }}
                        />
                        <span class="file-name">{item.name}</span>
                        <div class="file-hover-actions">
                          <button
                            innerHTML={filesActions.rename}
                            onClick={() => { changeSelectedFile(item, true), setShowRenameFilePopup(true) }}
                          ></button>
                          <Show
                            when={
                              (props.showeditoption || props.showeditoption == 'true') &&
                              ['png', 'jpg', 'jpeg'].includes(item.hash.split('.').pop()?.toLowerCase())
                            }
                          >
                            <button innerHTML={filesActions.edit}></button>
                          </Show>
                          <a href={isEmpty(item.preview) ? item.url : item.preview} target="_blank" innerHTML={filesActions.preview}></a>
                          <button innerHTML={filesActions.delete} onClick={() => { changeSelectedFile(item, true), setShowDeleteFilePopup(true) }}></button>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          </Show>
          <Show when={pagination() && pagination().last_page > 1}>
            <Pagination />
          </Show>
        </div>
      </Show>
    )
  }

  function StockList() {
    return (
      <Show when={!loading()}>
        <div class="files-grid-list-box">
          <Show when={filterGridList() == 'grid'} fallback={
            <div class="files-list">
              <Show when={!isEmpty(stockFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>No files yet.</span>
                </div>
              }>
                <For each={stockFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div class="file-el-left">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={isEmpty(item.preview) ? item.url : item.preview}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                        </div>
                        <div class="file-el-right">
                          <a
                            href={isEmpty(item.preview) ? item.url : item.preview}
                            target="_blank"
                            innerHTML={filesActions.preview}
                          ></a>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          }>
            <div class="files-grid">
              <Show when={!isEmpty(stockFiles()) || loading()} fallback={
                <div class="no-content">
                  <span>No files yet.</span>
                </div>
              }>
                <For each={stockFiles()}>
                  {(item, index) => (
                    <>
                      <label class="file-element">
                        <div
                          class="checkbox"
                          innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                        ></div>
                        <input
                          type="checkbox"
                          onInput={() => changeSelectedFile(item)}
                          checked={selectedFiles().includes(item.hash)}
                        />
                        {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                          <img
                            class="file-preview"
                            src={isEmpty(item.preview) ? item.url : item.preview}
                            onError={(e) => {
                              e.target.style.display = 'none'
                              e.target.nextSibling.style.display = 'block'
                            }}
                          />
                        ) : (
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                          />
                        )}
                        <div
                          class="file-icon"
                          innerHTML={previewSVGs[item.type]}
                          style={{ display: 'none' }}
                        />
                        <span class="file-name">{item.name}</span>
                        <div class="file-hover-actions">
                          <a href={isEmpty(item.preview) ? item.url : item.preview} target="_blank" innerHTML={filesActions.preview}></a>
                        </div>
                      </label>
                    </>
                  )}
                </For>
              </Show>
            </div>
          </Show>
          <Show when={pagination() && pagination().last_page > 1}>
            <Pagination />
          </Show>
        </div>
      </Show>
    )
  }

  // Views
  function Welcome() {
    return (
      <div class="main welcome">
        <div class="box-image">
          <p>Your Business Toolkit is Where You Manage your Files, Brand Assets & Custom Content</p>
          <div class="welcome-actions">
            <button
              class="card"
              onClick={() => { changeRoute('files'), openUploadPopup() }}
            >
              <div class="logo upload">
                <div innerHTML={welcomeToolkit.upload} />
              </div>
              <span class="title">Upload</span>
            </button>
            <button class="card" disabled>
              <div class="logo ai">
                <div innerHTML={welcomeToolkit.ai} />
              </div>
              <span class="title">AI Creator</span>
              <div class="coming-soon">
                <div innerHTML={welcomeToolkit.tab} />
                <span>Coming Soon</span>
              </div>

            </button>
            <button
              class="card"
              onClick={() => changeRoute('files')}
            >
              <div class="logo files">
                <div innerHTML={welcomeToolkit.files} />
              </div>
              <span class="title">My Files</span>
            </button>
            <button
              class="card"
              onClick={() => changeRoute('brands')}
            >
              <div class="logo brands">
                <div innerHTML={welcomeToolkit.brands} />
              </div>
              <span class="title">My Brands</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  function Files() {
    return (
      <div class="main files">
        <div class="sidebar-files">
          <div class="files-header">
            <div class="my-files">
              <div innerHTML={sidebar.myFiles} />
              <span>My Files</span>
            </div>
            <button class="ba-btn btn-arrow" innerHTML={sidebar.arrow} />
          </div>
          <hr />
          <div class="files-main" id="my-folders-list">
            <button
              classList={{ 'btn-folder': true, 'active': !showDeletedFolder() && currentFolder() == null }}
              onClick={() => changeCurrentFolder(null)}
              title="All"
            >
              <div class="folder-icon-title">
                <div innerHTML={sidebar.allFolders} />
                <span class="folder-title">All</span>
              </div>
            </button>
            <For each={myFolders()}>
              {(item, index) => (
                <>
                  <button
                    classList={{ 'btn-folder': true, 'active': !showDeletedFolder() && currentFolder() == item }}
                    onClick={() => changeCurrentFolder(item)}
                    title={item}
                  >
                    <div class="folder-icon-title">
                      <div innerHTML={sidebar.allFolders} />
                      <span class="folder-title">{item}</span>
                    </div>
                    <div
                      class="btn-folder-trash"
                      innerHTML={sidebar.trash}
                      onClick={() => setShowDeleteFolderPopup(true)}
                    ></div>
                  </button>
                </>
              )}
            </For>
          </div>
          <div class="files-footer">
            <button
              classList={{ 'ba-btn deleted': true, 'active': showDeletedFolder() }}
              onClick={() => changeShowDeletedFolder()}
            >
              <div innerHTML={sidebar.trash} />
              <span>Deleted</span>
            </button>
            <button
              class="ba-btn ba-grey new-folder"
              onClick={() => setShowNewFolderPopup(true)}
            >
              New Folder
            </button>
          </div>
        </div>
        <Show when={showDeletedFolder()} fallback={
          <div class="files-content">
            <Breadcrumb />
            <div class="files-nav-search">
              <FilesNav />
              <div class="search-new">
                <div class='ba-box-search-input'>
                  <input
                    type='search'
                    class='ba-input'
                    value={searchData()}
                    onInput={(e) => defineSearchData(e)}
                    placeholder='Search...'
                  />
                  <button
                    class='ba-button'
                    onClick={() => getFiles()}
                  >
                    <builderall-icon code='search' />
                  </button>
                </div>
                <button
                  class="ba-btn ba-blue"
                  onClick={() => openUploadPopup()}
                >
                  <builderall-icon code='2780'></builderall-icon>
                  New Upload
                </button>
              </div>
            </div>
            <Show when={!isEmpty(myFiles()) && myFiles().length > 0}>
              <FilesFilter />
            </Show>
            <FilesList />
          </div>
        }>
          <Show when={!loading()}>
            <div class="trash-content">
              <Breadcrumb />
              <div class="red-alert">
                <div innerHTML={alerts.red} />
                <span>Deleted files will be permanently removed after 30 days.</span>
              </div>
              <div class="files-grid">
                <Show when={!isEmpty(myDeletedFiles()) || loading()} fallback={
                  <div class="no-content">
                    <span>No deleted files yet.</span>
                  </div>
                }>
                  <For each={myDeletedFiles()}>
                    {(item, index) => (
                      <>
                        <label class="file-element">
                          <div
                            class="checkbox"
                            innerHTML={selectedFiles().includes(item.hash) ? checkbox.checked : checkbox.checkbox}
                          ></div>
                          <input
                            type="checkbox"
                            onInput={() => changeSelectedFile(item)}
                            checked={selectedFiles().includes(item.hash)}
                          />
                          {item.type === "IMAGE" || item.type === "VIDEO" || item.type === "GIF" ? (
                            <img
                              class="file-preview"
                              src={isEmpty(item.preview) ? item.url : item.preview}
                              onError={(e) => {
                                e.target.style.display = 'none'
                                e.target.nextSibling.style.display = 'block'
                              }}
                            />
                          ) : (
                            <div
                              class="file-icon"
                              innerHTML={previewSVGs[item.type]}
                            />
                          )}
                          <div
                            class="file-icon"
                            innerHTML={previewSVGs[item.type]}
                            style={{ display: 'none' }}
                          />
                          <span class="file-name">{item.name}</span>
                          <div class="file-hover-actions">
                            <a href={isEmpty(item.preview) ? item.url : item.preview} target="_blank" innerHTML={filesActions.preview}></a>
                          </div>
                        </label>
                      </>
                    )}
                  </For>
                </Show>
              </div>
            </div>
          </Show>
        </Show>
      </div>
    )
  }

  function Stock() {
    return (
      <div class="main stock">
        <div class="sidebar-files">
          <div class="files-header">
            <div class="my-files">
              <div innerHTML={sidebar.myFiles} />
              <span>Stock Elements</span>
            </div>
            <button class="ba-btn btn-arrow" innerHTML={sidebar.arrow} />
          </div>
          <hr />
          <div class="files-main" id="stock-folders-list">
            <For each={stockFolders()}>
              {(item, index) => (
                <>
                  <button
                    classList={{ 'btn-folder': true, 'active': currentFolder() == item.title }}
                    onClick={() => changeSecondaryMenuStatus(item.title)}
                    title={item.title}
                  >
                    <div class="folder-icon-title">
                      <div innerHTML={sidebar.allFolders} />
                      <span class="folder-title">{item.title}</span>
                    </div>
                  </button>
                  <Show when={item.show_secondary}>
                    <div class="secondary-menu">
                      <For each={item.folders}>
                      {(s, index) => (
                        <>
                          <button>{s}</button>
                        </>
                      )}
                      </For>
                    </div>
                  </Show>
                </>
              )}
            </For>
          </div>
        </div>
        <div class="files-content">
          <Breadcrumb />
          <div class="files-nav-search">
            <FilesNav />
            <div class="search-new">
              <div class='ba-box-search-input'>
                <input
                  type='search'
                  class='ba-input'
                  value={searchData()}
                  onInput={(e) => defineSearchData(e)}
                  placeholder='Search...'
                />
                <button
                  class='ba-button'
                  onClick={() => getFiles()}
                >
                  <builderall-icon code='search' />
                </button>
              </div>
            </div>
          </div>
          <Show when={!isEmpty(stockFiles()) && stockFiles().length > 0}>
            <StockFilter />
          </Show>
          <StockList />
        </div>
      </div>
    )
  }

  function Brands() {
    return (
      <div class="main brands">
        BRANDS
      </div>
    )
  }

  return (
    <>
      <Show when={props.show}>
        <style>{require("bundle-text:./styles/index.scss")}</style>
        <div class="modal-backdrop global-file-repository">
          <div class="modal">
            <div class="modal-header">
              <span class="ba-title">{translations['global-file-repository']}</span>
              <Show when={uploadLoading() && !isEmpty(percentCompleted())}>
                <div class="upload-progress">
                  <div innerHTML={anotherSVGs[getExtensionType()]} />
                  <div class='ba-progress'>
                    <span class='ba-progress-title'>{newUploadFile()[0].name}</span>
                    <div class="ba-progress-content">
                      <progress class='ba-progress-bar' value={percentCompleted()} max='100'></progress>
                      <span class='ba-progress-title'>{percentCompleted()}%</span>
                    </div>
                  </div>
                </div>
              </Show>
              <button
                onClick={() => closeModal()}
                class="ba-btn btn-close"
              >
                <builderall-icon code='64f7' />
              </button>
            </div>
            <builderall-loader loading={loading()}>
              <div class="modal-content">
                <div class="sidebar">
                  <nav>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'home' }}
                      onClick={() => changeRoute('home')}
                    >
                      <div innerHTML={sidebar.home} />
                      <span>Home</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'files' }}
                      onClick={() => changeRoute('files')}
                    >
                      <div innerHTML={sidebar.files} />
                      <span>My Files</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'stock' }}
                      onClick={() => changeRoute('stock')}
                    >
                      <div innerHTML={sidebar.stock} />
                      <span>Stock Elements</span>
                    </button>
                    <button
                      classList={{ 'ba-btn': true, 'active': step() == 'brands' }}
                      onClick={() => changeRoute('brands')}
                    >
                      <div innerHTML={sidebar.brands} />
                      <span>My Brands</span>
                    </button>
                    <button class="ba-btn" disabled>
                      <div innerHTML={sidebar.magic} />
                      <span>Magic Lab</span>
                    </button>
                  </nav>
                </div>
                {step() == 'home' && <Welcome />}
                {step() == 'files' && <Files />}
                {step() == 'stock' && <Stock />}
                {step() == 'brands' && <Brands />}
              </div>
            </builderall-loader>
            <div class="modal-footer">
              <a
                class='ba-progress'
                href="https://office.builderall.com/us/office/edit-my-account#quotas"
                target="_blank"
              >
                <progress
                  classList={{ 'ba-progress-bar': true, 'ba-limit-alert': quotas().in_use > quotas().released }}
                  value={quotas().released > quotas().in_use ? quotas().in_use : quotas().released}
                  max={quotas().released > quotas().in_use ? quotas().released : quotas().in_use}
                ></progress>
                <span class='ba-progress-data'>
                  {(quotas().in_use / 1024).toFixed(2)}gb of {(quotas().released / 1024).toFixed(2)}gb
                </span>
              </a>
              <div class="right-actions">
                <button
                  onClick={() => closeModal()}
                  class="ba-btn ba-white"
                >
                  Close Toolkit
                </button>
                <Show when={step() == 'files'}>
                  <Show when={showDeletedFolder()} fallback={
                    <button
                      class="ba-btn ba-blue"
                      ba-tooltip-position="top"
                      ba-tooltip-title={selectedFiles().length > 1 || (!isEmpty(myFiles()) && !validateFile(myFiles().find(x => x.hash === selectedFiles()[0]))) ? translations['files-are-not-format'] : null}
                      ba-tooltip-color="dark"
                      onClick={() => continueFile()}
                      disabled={isEmpty(selectedFiles()) || selectedFiles().length > 1 || (!isEmpty(myFiles()) && !validateFile(myFiles().find(x => x.hash === selectedFiles()[0])))}
                    >
                      {translations['apply']}
                      {!isEmpty(selectedFiles()) && selectedFiles().length > 0 ? ` (${selectedFiles().length})` : ''}
                    </button>
                  }>
                    <button
                      class="ba-btn ba-blue"
                      onClick={() => restoreFiles()}
                      disabled={isEmpty(selectedFiles())}
                    >
                      Restore
                      {!isEmpty(selectedFiles()) && selectedFiles().length > 0 ? ` (${selectedFiles().length})` : ''}
                    </button>
                  </Show>
                </Show>
              </div>
            </div>
            {/* Popups */}
            <Show when={showUploadFilePopup()}>
              <UploadFilePopup />
            </Show>
            <Show when={showDeleteFilePopup()}>
              <DeleteFilePopup />
            </Show>
            <Show when={showDeleteFolderPopup()}>
              <DeleteFolderPopup />
            </Show>
            <Show when={showNewFolderPopup()}>
              <NewFolderPopup />
            </Show>
            <Show when={showRenameFilePopup()}>
              <RenameFilePopup />
            </Show>
            <Show when={showMoveFilePopup()}>
              <MoveFilePopup />
            </Show>
            <div class={`ba-toast ${toastData().type} ${showToast() ? "show" : "hide"}`}>
              <div class='ba-toast-alert'>
                <div class='ba-left'>
                  <span class='ba-icon'></span>
                  <span class='ba-text'>{toastData().message}</span>
                </div>
                <span onClick={() => setShowToast(false)} class='ba-close-btn'></span>
              </div>
            </div>
          </div>
        </div>
      </Show>
    </>
  )
})